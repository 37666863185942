import * as React from "react"

import { Link } from "gatsby"

//import "./games.styles.css"

const IndexPage = () => {
  return (
    <main className="centered">
      <title>Noodle Nectar</title>
      <div className="hero">
        <div className="copy">
          <h1>Noodle Nectar</h1>
          <h2>nutrient dense games for your brain</h2>
        </div>
        <img src="http://placehold.it/1200x800" alt="some alt text" />
      </div>
      <h3>Current Games</h3>
      <ul className="tile-grid">
        <li>
          <Link to="/pairs">
            <img src="http://placehold.it/500x300" alt="game title cover" role="presentation" />
            <span className="tile__title">Play Pairs now</span>
          </Link>
        </li>
        <li>
          <a href="#">
            <img src="http://placehold.it/500x300" alt="game title cover" role="presentation" />
            <span className="tile__title">Play [Title here] now</span>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="http://placehold.it/500x300" alt="game title cover" role="presentation" />
            <span className="tile__title">Play [Title here] now</span>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="http://placehold.it/500x300" alt="game title cover" role="presentation" />
            <span className="tile__title">Play [Title here] now</span>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="http://placehold.it/500x300" alt="game title cover" role="presentation" />
            <span className="tile__title">Play [Title here] now</span>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="http://placehold.it/500x300" alt="game title cover" role="presentation" />
            <span className="tile__title">Play [Title here] now</span>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="http://placehold.it/500x300" alt="game title cover" role="presentation" />
            <span className="tile__title">Play [Title here] now</span>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="http://placehold.it/500x300" alt="game title cover" role="presentation" />
            <span className="tile__title">Play [Title here] now</span>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="http://placehold.it/500x300" alt="game title cover" role="presentation" />
            <span className="tile__title">Play [Title here] now</span>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="http://placehold.it/500x300" alt="game title cover" role="presentation" />
            <span className="tile__title">Play [Title here] now</span>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="http://placehold.it/500x300" alt="game title cover" role="presentation" />
            <span className="tile__title">Play [Title here] now</span>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="http://placehold.it/500x300" alt="game title cover" role="presentation" />
            <span className="tile__title">Play [Title here] now</span>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="http://placehold.it/500x300" alt="game title cover" role="presentation" />
            <span className="tile__title">Play [Title here] now</span>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="http://placehold.it/500x300" alt="game title cover" role="presentation" />
            <span className="tile__title">Play [Title here] now</span>
          </a>
        </li>
      </ul>
    </main>
  )
}

export default IndexPage
